import { SmartRouter } from '@pancakeswap/smart-router/evm'
import throttle from 'lodash/throttle'
import { useMemo } from 'react'
import { ArrowUpDownIcon, Box, Flex, IconButton, LinkExternal, useModal } from '@pancakeswap/uikit'

// import { shouldShowMMLiquidityError } from 'views/Swap/MMLinkPools/utils/exchange'
// import { MMLiquidityWarning } from 'views/Swap/MMLinkPools/components/MMLiquidityWarning'
import SettingsModal from 'components/EMenu/GlobalSettings/SettingsModal'
import { SettingsMode } from 'components/EMenu/GlobalSettings/types'
import styled from 'styled-components'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

import { useDerivedBestTradeWithMM } from '../MMLinkPools/hooks/useDerivedSwapInfoWithMM'
import { useCheckInsufficientError } from './hooks/useCheckSufficient'
import {
  FormHeader,
  FormMain,
  MMTradeDetail,
  PricingAndSlippage,
  SwapCommitButton,
  TradeDetails,
  BuyCryptoLink,
} from './containers'
import { MMCommitButton } from './containers/MMCommitButton'
import { useSwapBestTrade } from './hooks'

const BridgeContainer = styled.div<{
  chainId?: number;
}>`
margin-top: 20px;
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-around;
width: 100%;
border-radius: 15px;

background: rgba(255, 255, 255, 0.2);
border-radius: 15px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
padding: 16px;
`;

//   background-color: ${({ chainId }) => (chainId === 1 ? "red" : "blue")}


const BridgeLogo = styled.img`
  width: 32px;
  height: 32px;
`

export function V3SwapForm() {
  const { chainId } = useActiveWeb3React();
  const { isLoading, trade, refresh, syncing, isStale, error } = useSwapBestTrade()
  const mm = useDerivedBestTradeWithMM(trade)
  const throttledHandleRefresh = useMemo(
    () =>
      throttle(() => {
        refresh()
      }, 3000),
    [refresh],
  )

  const finalTrade = mm.isMMBetter ? mm?.mmTradeInfo?.trade : trade

  const tradeLoaded = !isLoading

  // const insufficientFundCurrency = useCheckInsufficientError(trade)
  const [onPresentSettingsModal] = useModal(<SettingsModal mode={SettingsMode.SWAP_LIQUIDITY} />)

  return (
    <>
      <Flex style={{ width: '100%' }} justifyContent="flex-start" p="0 1em 2em" alignItems="center">
        <FormHeader onRefresh={throttledHandleRefresh} refreshDisabled={!tradeLoaded || syncing || !isStale} />
        <IconButton onClick={onPresentSettingsModal} variant="text" scale="sm" >
          <img src="/assets/edit.png" width={20} height={20} alt="" />
        </IconButton>
      </Flex>
      <FormMain
        tradeLoading={mm.isMMBetter ? false : !tradeLoaded}
        pricingAndSlippage={
          mm.isMMBetter ? (
            <MMTradeDetail loaded={!mm.mmOrderBookTrade.isLoading} mmTrade={mm.mmTradeInfo} />
          ) : (
            <TradeDetails loaded={tradeLoaded} trade={trade} />
          )
        }
        inputAmount={finalTrade?.inputAmount}
        outputAmount={finalTrade?.outputAmount}
        swapCommitButton={
          mm?.isMMBetter ? (
            <MMCommitButton {...mm} />
          ) : (
            <SwapCommitButton trade={trade} tradeError={error} tradeLoading={!tradeLoaded} />
          )
        }
      />

      {/* <BuyCryptoLink currency={insufficientFundCurrency} /> */}

      {/* {(shouldShowMMLiquidityError(mm?.mmOrderBookTrade?.inputError) || mm?.mmRFQTrade?.error) && !trade && (
        <Box mt="5px">
          <MMLiquidityWarning />
        </Box>
      )} */}

      <BridgeContainer>
        <div style={{ width: '32px', height: "32px" }}>
          <BridgeLogo src={chainId === 1 ? "/assets/symbols/wpls.png" : "/assets/symbols/eweth.png"} />
        </div>

        <div>
          <p>{chainId === 1 ? "Pulsechain token bridge" : "Ethereum token bridge"}</p>
        </div>
        <div>
          <LinkExternal href='https://bridge.9inch.io/#/bridge' bold={false} small />
        </div>
      </BridgeContainer>
    </>
  )
}
